/* @define Pagination */

.Pagination {
	--Pagination-link-icon-size: 0.75em;
	--Pagination-link-icon-offset: calc(var(--Pagination-link-icon-size) + 0.5em);

	display: flex;
	gap: 1.25rem;
	margin-block-start: 3em;
}

.Pagination-link[rel="prev"]::before,
.Pagination-link[rel="next"]::after {
	background-size: var(--Pagination-link-icon-size);
	block-size: var(--Pagination-link-icon-size);
	content: "";
	inline-size: var(--Pagination-link-icon-size);
	inset-block-start: calc(50% - var(--link-underline-offset));
	position: absolute;
}

.Pagination-link[rel="prev"]::before {
	background: url("../../icons/arrow-back.svg") left no-repeat;
	inset-inline-start: calc(-1 * var(--Pagination-link-icon-offset));
}

.Pagination-linkWrapper--next {
	margin-inline-start: auto;
	text-align: end;
}

.Pagination-link[rel="next"]::after {
	background: url("../../icons/arrow.svg") right no-repeat;
	inset-inline-end: calc(-1 * var(--Pagination-link-icon-offset));
}
