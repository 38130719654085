/* @define Docs */

.Docs-nav {
	border-inline-end: var(--layout-border);
	overflow-y: auto;
}

.Docs-content {
	box-sizing: border-box;
	inline-size: 100%;
	margin-inline: auto;
	max-inline-size: 80ch;
	padding: 3.5rem var(--page-spacing--fluid);
}

@media (max-width: 58.5em) {
	.Docs-nav {
		block-size: 100vh;
		block-size: 100dvh;
		box-sizing: border-box;
		inline-size: 100%;
		inset-block-start: 0;
		overscroll-behavior-y: contain;
		position: fixed;
		z-index: 1;
	}

	.Docs-nav:not(:target) {
		display: none;
	}
}

@media (min-width: 58.5625em) {
	.Docs {
		--nav-width: 20rem;
		display: grid;

		/* pre element in content needs fixed size to force scollbar */
		grid-template-columns: var(--nav-width) calc(100% - var(--nav-width));
	}

	.Docs-nav {
		inset-block-start: var(--large-header-block-size);
		max-block-size: calc(
			100vh - var(--large-header-block-size) - var(--layout-border-width)
		);
		position: sticky;
	}
}
