/* PrismJS 1.27.0
https://prismjs.com/download.html#themes=prism&languages=markup+css+clike+javascript+markup-templating+php+yaml */
/* ATTENTION: This file is based on PrismJS, but adapted! */
code,
pre {
	color: #000;
	background: 0 0;
	text-shadow: 0 1px #fff;
	font-family: SuisseIntlMono, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
	font-size: var(--typo-code-font-size);
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}
@media print {
	code,
	pre {
		text-shadow: none;
	}
}
pre {
	padding: 1em;
	overflow: auto;
}
:not(pre) > code,
pre {
	background: var(--color-background-grey);
}
:not(pre) > code {
	padding: 0.1em 0.2em;
	white-space: normal;
}
.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
	color: #708090;
}
.token.punctuation {
	color: #999;
}
.token.namespace {
	opacity: 0.7;
}
.token.boolean,
.token.constant,
.token.deleted,
.token.number,
.token.property,
.token.symbol,
.token.tag {
	color: #d0491e;
}
.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string {
	color: #008762;
}
.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url {
	color: #9a6e3a;
	background: hsla(0, 0%, 100%, 0.5);
}
.token.atrule,
.token.attr-value,
.token.keyword {
	color: #0079d2;
}
.token.class-name,
.token.function {
	color: #dd4a68;
}
.token.important,
.token.regex,
.token.variable {
	color: #e90;
}
.token.bold,
.token.important {
	font-weight: 700;
}
.token.italic {
	font-style: italic;
}
.token.entity {
	cursor: help;
}
