/* @define RichText; weak */

.RichText {
	--lh-1: calc(
		var(--typo-Default-font-size) * var(--typo-Default-line-height) / 2
	);
	--lh-2: calc(2 * var(--lh-1));
	--lh-3: calc(3 * var(--lh-1));
	--lh-4: calc(4 * var(--lh-1));

	--font-weight-bold: var(--typo-font-weight-bold);

	/* background: linear-gradient(to bottom, #ffe2e2 50%, transparent 50%);
	background-size: 100% var(--lh-2); */
}

.RichText--large {
	--lh-1: calc(
		var(--typo-DefaultLarge-font-size) * var(--typo-DefaultLarge-line-height) /
			2
	);

	font-size: var(--typo-DefaultLarge-font-size);
	letter-spacing: var(--typo-DefaultLarge-letter-spacing);
	line-height: var(--typo-DefaultLarge-line-height);
}

.RichText :is(h1, h2, h3, h4, h5, h6) {
	text-wrap: balance;
}

.RichText :is(h1, h2, h3, h4, h5, h6, p, ul, ol) {
	max-inline-size: 52rem;
}

/* heading styles */

.RichText :where(h1, h2, h3, h4, h5, h6) {
	font-size: var(--font-size);
	font-weight: var(--font-weight);

	/*
  * we use an absolute line height here because we want to make sure
  * that the anchor element is always correctly aligned with the heading.
  * So, the anchor element should in this case NOT have a relative line height,
  * instead the line height needs to be tightly coupled to the font size.
  */
	line-height: calc(var(--font-size) * var(--line-height));
	position: relative;
	scroll-margin-block-start: calc(var(--small-header-block-size) + 1em);
}

.RichText h1 {
	--font-size: var(--typo-h1-font-size);
	--line-height: var(--typo-h1-line-height);
	--font-weight: var(--typo-h1-font-weight);
}

.RichText h2 {
	--font-size: var(--typo-h2-font-size);
	--line-height: var(--typo-h2-line-height);
	--font-weight: var(--typo-h2-font-weight);
}

.RichText h3 {
	--font-size: var(--typo-h3-font-size);
	--line-height: var(--typo-h3-line-height);
	--font-weight: var(--typo-h3-font-weight);
}

.RichText h4 {
	--font-size: var(--typo-h4-font-size);
	--line-height: var(--typo-h4-line-height);
	--font-weight: var(--typo-h4-font-weight);
}

.RichText .header-anchor {
	color: var(--color-green);
	inset-block-start: 0;
	inset-inline-end: 100%;
	padding-inline-end: 0.3125rem;
	position: absolute;
}

@media (hover: hover) {
	.RichText .header-anchor {
		clip: rect(0, 0, 0, 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		width: 1px;
	}

	.RichText :where(h1, h2, h3, h4, h5, h6):hover .header-anchor,
	.RichText .header-anchor:focus {
		clip: unset;
		height: unset;
		margin: unset;
		width: unset;
	}
}

/* spacings  */

/**
 * HEADING + HEADING
 */

.RichText h1 + h2,
.RichText h2 + h3,
.RichText h3 + h4 {
	margin-block-start: var(--lh-2);
}

/**
 * PARAGRAPH + PARAGRAPH
 */

.RichText
	:is(p, ul, ol, pre, table, blockquote, img, iframe)
	+ :is(p, ul, ol, pre, table, blockquote, img, iframe) {
	margin-block-start: var(--lh-2);
}

.RichText li p + p {
	margin-block-start: calc(var(--lh-1) / 2);
}

/* Delete 'at rule' when firefox supports the :has pseudo selector
and keep the first rule: li:not(:first-child):has(p + p) */
@supports selector(:has(*)) {
	.RichText li:not(:first-child):has(p + p) {
		margin-block-start: var(--lh-2);
	}
}

@supports not selector(:has(*)) {
	.RichText li:not(:first-child):not(:only-child),
	.RichText li:first-child + li:not(:only-child) {
		margin-block-start: var(--lh-2);
	}
}

/**
 * PARAGRAPH + MEDIA
 */

.RichText :is(p, ul, ol, pre, table, blockquote, img, iframe) + .Picture {
	margin-block-start: var(--lh-4);
}

/**
 * H1 + PARAGRAPH
 */

.RichText h1 + :is(p, ul, ol, pre, table, blockquote, img, iframe) {
	margin-block-start: var(--lh-3);
}

/**
 * H2 + PARAGRAPH
 */

.RichText h2 + :is(p, ul, ol, pre, table, blockquote, img, iframe) {
	margin-block-start: var(--lh-2);
}

/**
 * H3 + PARAGRAPH
 */

.RichText h3 + :is(p, ul, ol, pre, table, blockquote, img, iframe) {
	margin-block-start: var(--lh-2);
}

/**
 * H4 + PARAGRAPH
 */

.RichText h4 + :is(p, ul, ol, pre, table, blockquote, img, iframe) {
	margin-block-start: var(--lh-1);
}

/**
 * PARAGRAPH + H1
 */

.RichText :is(p, ul, ol, pre, table, blockquote, img, iframe) + h1 {
	margin-block-start: var(--lh-4);
}

/**
 * PARAGRAPH + H2
 */

.RichText :is(p, ul, ol, pre, table, blockquote, img, iframe) + h2 {
	margin-block-start: var(--lh-4);
}

/**
 * PARAGRAPH + H3
 */

.RichText :is(p, ul, ol, pre, table, blockquote, img, iframe) + h3 {
	margin-block-start: var(--lh-4);
}

/**
 * PARAGRAPH + H4
 */

.RichText :is(p, ul, ol, pre, table, blockquote, img, iframe) + h4 {
	margin-block-start: var(--lh-3);
}

.RichText li + li,
.RichText :is(ul, ol) :is(ul, ol) li:first-child {
	margin-block-start: calc(var(--lh-1) / 2);
}

.RichText :is(pre, pre code, iframe, img, table) {
	inline-size: 100%;
}

/* codeblock */

.RichText pre {
	align-items: center;
	display: flex;
	margin: 0;
	white-space: pre-wrap;
	word-wrap: break-word;
}

.RichText pre code {
	display: block;
}

.RichText pre,
.RichText code:not(:is(pre > code)) {
	background-color: var(--color-background-grey);
}

.RichText pre {
	padding-block: 0.5rem;
	padding-inline: 0.75rem;
}

.RichText code:not(:is(pre > code)) {
	padding-inline: 0.5rem;
}

/* lists */

.RichText li::marker {
	color: var(--color-green);
}

.RichText :is(ul, ol) {
	list-style: revert;
	padding-inline-start: revert;
}

/* tables */
.RichText th {
	font-weight: 600;
	padding-inline: 5rem;
}

.RichText th,
.RichText td {
	border: 0.125em solid var(--color-line-grey);
	padding: 0.625rem;
}

.RichText tbody tr:nth-child(odd) {
	background-color: var(--color-background-grey);
}

/* other tags */

.RichText :is(strong, b) {
	font-weight: var(--font-weight-bold);
}

.RichText :is(em, i) {
	font-style: italic;
}

.RichText :is(sub, sup) {
	all: revert;
	line-height: 1;
}

.RichText blockquote {
	border-inline-start: 0.4rem solid var(--c-b-light);
	padding-inline-start: 1em;
}

.RichText img {
	block-size: auto;
	inline-size: auto;
}
