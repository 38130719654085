/* @define Nav */

.Nav {
	--nav-spacing: var(--page-spacing--fixed);

	background-color: var(--color-background);
}

.Nav-list,
.Nav-subList {
	list-style: none;
}

.Nav-list > .Nav-listItem > .Nav-listItemLink {
	font-weight: 600;
	padding-inline-start: var(--nav-spacing);
}

.Nav-subList .Nav-listItemLink {
	font-size: var(--typo-reduced-font-size);
}

.Nav-listItemLink {
	display: block;
	padding-block: 0.5rem;
	padding-inline-end: 1.25rem;
	padding-inline-start: calc(var(--nav-spacing) * var(--level));
	position: relative;
}

.Nav-listItemLink[aria-current="page"] {
	color: var(--color-orange);
}

.Nav-listItemLink[aria-current="page"]::before {
	background-color: currentColor;
	block-size: 100%;
	content: "";
	display: block;
	inline-size: 0.3em;
	inset-block-start: 0;
	inset-inline-start: 0;
	position: absolute;
}

@media (max-width: 58.5em) {
	.Nav {
		min-block-size: 100%;
	}

	.Nav-contentLinkIcon {
		block-size: 1rem;
		fill: currentColor;
		inline-size: 1rem;
		margin-inline-start: var(--u-page-spacing);
		padding-block: 1.25rem;
	}
}

@media (min-width: 58.5625em) {
	.Nav {
		padding-block: var(--nav-spacing);
	}

	.Nav-contentLink {
		display: none;
	}
}
